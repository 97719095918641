import * as styles from "./project.module.css"

import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import mobileOnboarding10Img from "../../images/projects/safety-culture/mobile-onboarding-10.png"
import mobileOnboarding11Img from "../../images/projects/safety-culture/mobile-onboarding-11.png"
import mobileOnboarding12Img from "../../images/projects/safety-culture/mobile-onboarding-12.png"
import mobileOnboarding13Img from "../../images/projects/safety-culture/mobile-onboarding-13.png"
import mobileOnboarding14Img from "../../images/projects/safety-culture/mobile-onboarding-14.png"
import mobileOnboarding15Img from "../../images/projects/safety-culture/mobile-onboarding-15.png"
import mobileOnboarding16Img from "../../images/projects/safety-culture/mobile-onboarding-16.png"
import mobileOnboarding17Img from "../../images/projects/safety-culture/mobile-onboarding-17.png"
import mobileOnboarding1Img from "../../images/projects/safety-culture/mobile-onboarding-1.png"
import mobileOnboarding2Img from "../../images/projects/safety-culture/mobile-onboarding-2.png"
import mobileOnboarding3Img from "../../images/projects/safety-culture/mobile-onboarding-3.png"
import mobileOnboarding4Img from "../../images/projects/safety-culture/mobile-onboarding-4.png"
import mobileOnboarding5Img from "../../images/projects/safety-culture/mobile-onboarding-5.png"
import mobileOnboarding6Img from "../../images/projects/safety-culture/mobile-onboarding-6.png"
import mobileOnboarding7Img from "../../images/projects/safety-culture/mobile-onboarding-7.png"
import mobileOnboarding8Img from "../../images/projects/safety-culture/mobile-onboarding-8.png"
import mobileOnboarding9Img from "../../images/projects/safety-culture/mobile-onboarding-9.png"

const SafetyCultureOnboarding = () => (
  <Layout>
    <Seo title="Onboarding Experiments" />

    <Section>
      <Image img={mobileOnboarding1Img} />
      <Image img={mobileOnboarding2Img} />
      <Image img={mobileOnboarding3Img} />
    </Section>

    <Section className={styles.peachBackground}>
      <h1>What did the process look like?</h1>
    </Section>

    <Section>
      <Image img={mobileOnboarding4Img} />
      <Image img={mobileOnboarding5Img} />
      <Image img={mobileOnboarding6Img} />
    </Section>

    <Section className={styles.peachBackground}>
      <Image img={mobileOnboarding7Img} />
    </Section>

    <Section>
      <Image img={mobileOnboarding8Img} />
    </Section>
    
    <Section className={styles.blueBackground}>
      <h1>Design</h1>
    </Section>

    <Section>
      <Image img={mobileOnboarding9Img} />
      <Image img={mobileOnboarding10Img} />
      <Image img={mobileOnboarding11Img} />
      <Image img={mobileOnboarding12Img} />
      <Image img={mobileOnboarding13Img} />
      <Image img={mobileOnboarding14Img} />
      <Image img={mobileOnboarding15Img} />
      <Image img={mobileOnboarding16Img} />
      <Image img={mobileOnboarding17Img} />
    </Section>

    <Section>
      <PrevNext next="/project/deputy-product-tour" />
    </Section>
  </Layout>
)

export default SafetyCultureOnboarding
